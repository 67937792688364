// extracted by mini-css-extract-plugin
export var bg = "patientInfo-module--bg--FJYIC";
export var bgdesktop = "patientInfo-module--bgdesktop---HSpH";
export var bgmobile = "patientInfo-module--bgmobile--hytJI";
export var bgtablet = "patientInfo-module--bgtablet--dE7gz";
export var bgxl = "patientInfo-module--bgxl--dCgOC";
export var blurb = "patientInfo-module--blurb--m-5e9";
export var bottom = "patientInfo-module--bottom--9fh6W";
export var callout = "patientInfo-module--callout--Fu7Q1";
export var contentleft = "patientInfo-module--contentleft--41v8c";
export var downloadbtn = "patientInfo-module--downloadbtn--thq+r";
export var downloadlinks = "patientInfo-module--downloadlinks--s4wu+";
export var heading = "patientInfo-module--heading--wQ7az";
export var icon = "patientInfo-module--icon--woeSN";
export var insurance = "patientInfo-module--insurance--Sm5dM";
export var insurancebottom = "patientInfo-module--insurancebottom--diX2C";
export var insuranceimage = "patientInfo-module--insuranceimage--trurh";
export var left = "patientInfo-module--left--N06i8";
export var leftbottom = "patientInfo-module--leftbottom--KyhUp";
export var lefttop = "patientInfo-module--lefttop--pVU6m";
export var link = "patientInfo-module--link--WKnSI";
export var linkcard = "patientInfo-module--linkcard--dUl+T";
export var linkhover = "patientInfo-module--linkhover--TJu3P";
export var linktitle = "patientInfo-module--linktitle--xKkjs";
export var logo = "patientInfo-module--logo--oZOsR";
export var logolink = "patientInfo-module--logolink--EBi90";
export var patientcontent = "patientInfo-module--patientcontent--plztj";
export var patientheading = "patientInfo-module--patientheading--veDgV";
export var patientname = "patientInfo-module--patientname--4janN";
export var request = "patientInfo-module--request--fVs5I";
export var right = "patientInfo-module--right--fj0tb";
export var sronly = "patientInfo-module--sronly--ToBQi";
export var top = "patientInfo-module--top--DG054";