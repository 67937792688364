import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import useModalState from '../helpers/modalState';

import * as styleSheet from "./patientInfo.module.scss"

const PatientInfoTemplate = (props) => {


  const [showAppointmentModal, setShowAppointmentModal] = useModalState();

  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({modalOpen: true});
		}
	}

  function handleApptKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
			setShowAppointmentModal({modalOpen: true});
		}
  }

  

	const {
		data: {
			patientInfo,
			patientInfo: {
				title,
				ACFPatientInfo: {
					patientInfo: {
						left: {
							blurb,
							requestAppointmentLink,
							insurancesHeading,
							callout: {
								backgroundImage: calloutBG,
								blurb: calloutBlurb,
								heading: calloutHeading,
								link: calloutLink
							}
						},
						right: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							patientContent: content,
              downloadLinks
						}
					}
				}
			},
			home: {
				ACFHomePage: {
					hero: {
						callouts: {
							callout1: {
								heading: homeCalloutHeading,
								blurb: homeCalloutBlurb,
								makeAnAppointmentLinkText
							}
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							mobileNavigationLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			rightArrowYellow,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			calloutBG: genericCalloutBG
		}
	} = props;

	return (
		<Layout>
			<Seo post={patientInfo} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						{ blurb && <div className={styleSheet.patientheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styleSheet.request} /> }
						{ !requestAppointmentLink && makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.request} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{makeAnAppointmentLinkText}</button>}
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease-in" /> }
          {!introLink && makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false} data-sal="slide-up" data-sal-delay="500" data-sal-easing="ease-in">{makeAnAppointmentLinkText} <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button>}
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.left}>
					<div className={styleSheet.insurance}>
						<span className={styleSheet.heading}>{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styleSheet.insurancebottom}>
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styleSheet.insuranceimage} /> : null;
							})}
						</div>
					</div>
					<div className={styleSheet.callout}>
						<div className={styleSheet.left}>
							{ (genericCalloutBG || calloutBG) && <FluidImage image={calloutBG ? calloutBG : genericCalloutBG} passedClass={styleSheet.bg} /> }
						</div>
						<div className={styleSheet.right}>
							{ (calloutHeading || homeCalloutHeading) && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: calloutHeading ? calloutHeading : homeCalloutHeading }}></div> }
							{ (calloutBlurb || homeCalloutBlurb) && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: calloutBlurb ? calloutBlurb : homeCalloutBlurb}}></div> }

							{ calloutLink && <WPLink link={calloutLink} passedClass={styleSheet.link} /> }
              {!calloutLink && makeAnAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{makeAnAppointmentLinkText}</button>}
						</div>
					</div>
				</div>
				<div className={styleSheet.right}>
					<div className={styleSheet.patientcontent}>
						<div className={styleSheet.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						
              {downloadLinks && <ul className={styleSheet.downloadlinks}>
                {downloadLinks.map( (link, index) => {
									return (
										<li className={styleSheet.linkcard} key={`dllink-${index}`}>
                      <h3 className={styleSheet.linktitle}>{link.linkTitle}</h3>
                      <p><a className={styleSheet.downloadbtn} href={link.downloadLink} target="_blank" rel="noreferrer">Download <span className={styleSheet.sronly}>{link.linkTitle}</span></a></p>
										</li>
									)
								})}
              </ul>}
              
              
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  patientInfo: wpPage(id: {eq: $id}) {
    id
    title
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    ACFPatientInfo {
      patientInfo {
        left {
          blurb
          requestAppointmentLink {
            title
            url
            target
          }
          insurancesHeading
          callout {
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
                publicURL
                extension
              }
            }
            blurb
            heading
            link {
              target
              title
              url
            }
          }
        }
        right {
          introContent {
            blurb
            heading
            link {
              target
              title
              url
            }
          }
          patientContent
          downloadLinks {
            linkTitle
            downloadLink
          }
        }
      }
    }
  }
  home: wpPage(isFrontPage: {eq: true}) {
    ACFHomePage {
      hero {
        callouts {
          callout1 {
            heading
            blurb
            makeAnAppointmentLinkText
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  calloutBG: file(name: {eq: "callout_overlay_girl"}) {
    childImageSharp {
      gatsbyImageData(width: 295, layout: CONSTRAINED)
    }
  }
  insurance: allWpInsurance {
    nodes {
      ACFInsurance {
        logo {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 420, layout: CONSTRAINED)
            }
            publicURL
            extension
          }
        }
      }
    }
  }
}
`;

export default PatientInfoTemplate;